<template>
  <div class="screen__wrapper">
    <div v-if="actionType === 2" class="stats__table">
      <div class="stats__column stats__column-title">
        <div class="stats__row stats__row-title">Агент</div>
        <div class="stats__row stats__row-title">
          <span>Об'єкти</span>
        </div>
        <div class="stats__row stats__row-title">
          <span>Покупці\Орендарі</span>
        </div>
      </div>
      <div class="stats__column" v-for="agent of stats" :key="agent.id" :class="{'stats__column--del': agent.isBlocked, 'stats__column--light': agent.id == null}">
        <div class="stats__row">{{ agent.name }}</div>
        <div class="stats__row">{{ agent.properties }}</div>
        <div class="stats__row">{{ agent.buyers }}</div>
      </div>
    </div>
    <div v-else-if="actionType === 4" class="stats__table stats__table-call">
      <div class="stats__column stats__column-title">
        <div class="stats__row stats__row-title">Агент</div>
        <div class="stats__row stats__row-title">
          <span class="stats__row--green ">Разом</span>
        </div>
        <div class="stats__row stats__row-title">
          <span>ID</span>
        </div>
        <div class="stats__row stats__row-title">
          <span>Інше АН</span>
        </div>
      </div>
      <div class="stats__column" v-for="agent of stats" :key="agent.id" :class="{'stats__column--del': agent.isBlocked, 'stats__column--light': agent.id == null}">
        <div class="stats__row">{{ agent.name }}</div>
        <div class="stats__row">
          <span class="stats__row--green">{{ agent.total }} </span>
        </div>
        <div class="stats__row">
          <span>{{ agent.ourAgency }} </span>
        </div>
        <div class="stats__row">
          <span>{{ agent.otherAgency }} </span>
        </div>
      </div>
    </div>
    <table v-else-if="actionType === 8" class="stats__table--production">
      <tr>
        <th>Агент</th>
        <th>Розклейка</th>
        <th>Листівки</th>
        <th>Instagram</th>
        <th>Facebook</th>
        <th>TikTok</th>
        <th>Розклейка під обʼєкт</th>
        <th>Банер на обʼєкт</th>
        <th>Повід-ння сусідів</th>
      </tr>
      <tr v-for="agent of stats" :key="agent.id" :class="{'del': agent.isBlocked, 'light': agent.id == null}">
        <td>{{ agent.name }}</td>
        <td>{{ agent.posting }}</td>
        <td>{{ agent.postcards }}</td>
        <td>{{ agent.instagram }}</td>
        <td>{{ agent.facebook }}</td>
        <td>{{ agent.tiktok }}</td>
        <td>{{ agent.postingForObject }}</td>
        <td>{{ agent.bannerForObject }}</td>
        <td>{{ agent.neighborsNotification }}</td>
      </tr>
    </table>
    <div v-else class="stats__table">
      <div class="stats__column stats__column-title">
        <div class="stats__row stats__row-title">Агент</div>
        <div class="stats__row stats__row-title stats__row--three">
          <span class="stats__row--green ">Разом ID</span>
          <span>Об'єкт</span>
          <div>{{ buyerLabel }}</div>
        </div>
        <div class="stats__row stats__row-title stats__row--three">
          <span class="stats__row--green ">Разом Інше</span>
          <span>Об'єкт</span>
          <div>{{ buyerLabel }}</div>
        </div>
      </div>
      <div class="stats__column" v-for="agent of stats" :key="agent.id" :class="{'stats__column--del': agent.isBlocked, 'stats__column--light': agent.id == null}">
        <div class="stats__row">{{ agent.name }}</div>
        <div class="stats__row stats__row--three">
          <span class="stats__row--green">{{ agent.ourTotal }} </span>
          <span>{{ agent.ourProperties }}</span>
          <span>{{ agent.ourBuyers }}</span>
        </div>
        <div class="stats__row stats__row--three">
          <span class="stats__row--green">{{ agent.otherTotal }} </span>
          <span>{{ agent.otherProperties }}</span>
          <span>{{ agent.otherBuyers }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
  name: 'StatsActions',
  computed: {
    ...mapState({
      stats: s => s.stats.actions.stats,
      actionType: s => s.stats.actions.actionType
    }),
    buyerLabel() {
      switch (this.actionType) {
        case 1:
        case 4:
          return 'Покупець/Орендар'
        case 7:
          return 'Орендар'
        default:
          return 'Покупець'
      }
    }
  },
  async created() {
    await this.fetch(this.actionType)
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapActions({
      fetch: 'stats/actions/fetch'
    }),
    ...mapMutations({
      reset: 'stats/actions/reset'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
