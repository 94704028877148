var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen__wrapper" }, [
    _vm.actionType === 2
      ? _c(
          "div",
          { staticClass: "stats__table" },
          [
            _vm._m(0),
            _vm._l(_vm.stats, function(agent) {
              return _c(
                "div",
                {
                  key: agent.id,
                  staticClass: "stats__column",
                  class: {
                    "stats__column--del": agent.isBlocked,
                    "stats__column--light": agent.id == null
                  }
                },
                [
                  _c("div", { staticClass: "stats__row" }, [
                    _vm._v(_vm._s(agent.name))
                  ]),
                  _c("div", { staticClass: "stats__row" }, [
                    _vm._v(_vm._s(agent.properties))
                  ]),
                  _c("div", { staticClass: "stats__row" }, [
                    _vm._v(_vm._s(agent.buyers))
                  ])
                ]
              )
            })
          ],
          2
        )
      : _vm.actionType === 4
      ? _c(
          "div",
          { staticClass: "stats__table stats__table-call" },
          [
            _vm._m(1),
            _vm._l(_vm.stats, function(agent) {
              return _c(
                "div",
                {
                  key: agent.id,
                  staticClass: "stats__column",
                  class: {
                    "stats__column--del": agent.isBlocked,
                    "stats__column--light": agent.id == null
                  }
                },
                [
                  _c("div", { staticClass: "stats__row" }, [
                    _vm._v(_vm._s(agent.name))
                  ]),
                  _c("div", { staticClass: "stats__row" }, [
                    _c("span", { staticClass: "stats__row--green" }, [
                      _vm._v(_vm._s(agent.total) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "stats__row" }, [
                    _c("span", [_vm._v(_vm._s(agent.ourAgency) + " ")])
                  ]),
                  _c("div", { staticClass: "stats__row" }, [
                    _c("span", [_vm._v(_vm._s(agent.otherAgency) + " ")])
                  ])
                ]
              )
            })
          ],
          2
        )
      : _vm.actionType === 8
      ? _c(
          "table",
          { staticClass: "stats__table--production" },
          [
            _vm._m(2),
            _vm._l(_vm.stats, function(agent) {
              return _c(
                "tr",
                {
                  key: agent.id,
                  class: { del: agent.isBlocked, light: agent.id == null }
                },
                [
                  _c("td", [_vm._v(_vm._s(agent.name))]),
                  _c("td", [_vm._v(_vm._s(agent.posting))]),
                  _c("td", [_vm._v(_vm._s(agent.postcards))]),
                  _c("td", [_vm._v(_vm._s(agent.instagram))]),
                  _c("td", [_vm._v(_vm._s(agent.facebook))]),
                  _c("td", [_vm._v(_vm._s(agent.tiktok))]),
                  _c("td", [_vm._v(_vm._s(agent.postingForObject))]),
                  _c("td", [_vm._v(_vm._s(agent.bannerForObject))]),
                  _c("td", [_vm._v(_vm._s(agent.neighborsNotification))])
                ]
              )
            })
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "stats__table" },
          [
            _c("div", { staticClass: "stats__column stats__column-title" }, [
              _c("div", { staticClass: "stats__row stats__row-title" }, [
                _vm._v("Агент")
              ]),
              _c(
                "div",
                {
                  staticClass: "stats__row stats__row-title stats__row--three"
                },
                [
                  _c("span", { staticClass: "stats__row--green " }, [
                    _vm._v("Разом ID")
                  ]),
                  _c("span", [_vm._v("Об'єкт")]),
                  _c("div", [_vm._v(_vm._s(_vm.buyerLabel))])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "stats__row stats__row-title stats__row--three"
                },
                [
                  _c("span", { staticClass: "stats__row--green " }, [
                    _vm._v("Разом Інше")
                  ]),
                  _c("span", [_vm._v("Об'єкт")]),
                  _c("div", [_vm._v(_vm._s(_vm.buyerLabel))])
                ]
              )
            ]),
            _vm._l(_vm.stats, function(agent) {
              return _c(
                "div",
                {
                  key: agent.id,
                  staticClass: "stats__column",
                  class: {
                    "stats__column--del": agent.isBlocked,
                    "stats__column--light": agent.id == null
                  }
                },
                [
                  _c("div", { staticClass: "stats__row" }, [
                    _vm._v(_vm._s(agent.name))
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--three" }, [
                    _c("span", { staticClass: "stats__row--green" }, [
                      _vm._v(_vm._s(agent.ourTotal) + " ")
                    ]),
                    _c("span", [_vm._v(_vm._s(agent.ourProperties))]),
                    _c("span", [_vm._v(_vm._s(agent.ourBuyers))])
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--three" }, [
                    _c("span", { staticClass: "stats__row--green" }, [
                      _vm._v(_vm._s(agent.otherTotal) + " ")
                    ]),
                    _c("span", [_vm._v(_vm._s(agent.otherProperties))]),
                    _c("span", [_vm._v(_vm._s(agent.otherBuyers))])
                  ])
                ]
              )
            })
          ],
          2
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stats__column stats__column-title" }, [
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _vm._v("Агент")
      ]),
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _c("span", [_vm._v("Об'єкти")])
      ]),
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _c("span", [_vm._v("Покупці\\Орендарі")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stats__column stats__column-title" }, [
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _vm._v("Агент")
      ]),
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _c("span", { staticClass: "stats__row--green " }, [_vm._v("Разом")])
      ]),
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _c("span", [_vm._v("ID")])
      ]),
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _c("span", [_vm._v("Інше АН")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Агент")]),
      _c("th", [_vm._v("Розклейка")]),
      _c("th", [_vm._v("Листівки")]),
      _c("th", [_vm._v("Instagram")]),
      _c("th", [_vm._v("Facebook")]),
      _c("th", [_vm._v("TikTok")]),
      _c("th", [_vm._v("Розклейка під обʼєкт")]),
      _c("th", [_vm._v("Банер на обʼєкт")]),
      _c("th", [_vm._v("Повід-ння сусідів")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }